<template>
    <div class="tabla-estrategia">



        <main>

            <!-- Agrego un custom total porque con menos de 10 elementos desaparece el pagindador  -->
            <span v-if="datafiltered.length <= 10" style="display: flex; align-content: start; margin-bottom: -20px;">
                Total: <strong style="font-weight: bold;">{{ datafiltered.length | dot }}</strong>
            </span>

            <div class="tabla">
                <v-client-table ref="table" :data="datafiltered" :columns="columns[type]" :options="options"
                    :notsearchable="!cansearch">

                    <span class="custom-td" slot="aavv" slot-scope="{ row }">
                        <span v-if="row.aavv">{{ (row.aavv) }}</span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td" slot="mesa" slot-scope="{ row }">
                        <span v-if="row.mesa">{{ (row.mesa) }}</span>
                        <span v-else>--</span>
                    </span>

                    <span class="custom-td" slot="grupo" slot-scope="{ row }">
                        <span v-if="row.grupo">{{ (row.grupo) }}</span>
                        <span v-else>--</span>
                    </span>

                    <!-- <span class="custom-td -actuacion" slot="actuacion" slot-scope="{ row }">
                        <span v-if="row.nombre">{{ row.nombre }}</span>
                        <span v-else>--</span>
                    </span> -->




                    <span class="custom-td -status" slot="Situacion" slot-scope="{ row }">
                        <span class="status" :status="'status-' + row.idactuacioncumplimientoaavv"
                            :title="cumplimiento(row.idactuacioncumplimientoaavv).nombre">{{
                                cumplimiento(row.idactuacioncumplimientoaavv).nombre
                                | estatus }}</span>
                    </span>


                    <!-- filtro de estado -->
                    <div class="estados" slot="afterFilter" v-if="$route.name != 'buscador'">
                        <div class="contenedor-input -full -m0">
                            <multiselect v-model="filter.status" :options="dominios.fakecumplimientoaavv"
                                placeholder="Estado" label="nombre" :multiple="true" :closeOnSelect="false"
                                :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre"
                                :searchable="false">
                            </multiselect>

                        </div>
                    </div>

                    <!-- filtro de mesas -->
                    <!-- <div class="estados" slot="afterFilter" v-if="$route.name != 'buscador'">
                        <div class="contenedor-input -full -m0">
                            <multiselect v-model="filter.mesa" :options="dominios.acuerdos_villa_mesa" placeholder="Mesa"
                                label="nombre" :multiple="true" :closeOnSelect="false" :tagPosition="'bottom'"
                                :showLabels="false" :showNoResults="false" track-by="nombre">
                            </multiselect>
                        </div>
                    </div> -->

                    <!-- filtro de grupos de trabajo -->
                    <div class="estados" slot="afterFilter" v-if="$route.name != 'buscador'">
                        <div class="contenedor-input -full -m0">
                            <multiselect v-model="filter.grupo" :options="grupoaavv(this.areaseleccionada.id)"
                                placeholder="Grupo de Trabajo" label="nombre" :multiple="true" :closeOnSelect="false"
                                :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                            </multiselect>
                        </div>
                    </div>


                    <span class="length" slot="beforeLimit">
                        Total: <strong>{{ datafiltered.length | dot }}</strong>
                    </span>



                </v-client-table>



            </div>

        </main>

    </div>
</template>


<script>

import { mapGetters } from 'vuex';

export default {
    name: 'resultados',
    props: ['data', 'cansearch', 'type', 'areaseleccionada'],
    mounted() {


        //console.log(this);
    },
    data: () => ({
        columns: {
            'actuaciones': [
                "mesa",
                "grupo",
                "aavv",
                // "actuacion",
                "Situacion",
            ],

        },
        options: {
            skin: 'table',
            highlightMatches: true,
            headings: {
                "aavv": "Acuerdo de la Villa",
                //"actuacion": "Actuación",
                "Situacion": "Situación",
                "mesa": "Mesa",
                "grupo": "Grupo de Trabajo",
                'cumplimiento': 'Grado de Cumplimiento'
            },
            texts: {
                filter: "Buscar:",
                filterBy: 'Filtrar por {column}',
                count: '',
                records: 'Resultados',
                limit: "Por Página: ",
                page: "Págína:",
                noResults: "No se han encontrado resultados",
                filterPlaceholder: "Escribe para buscar",
            },
            dateFormat: 'DD-MM-YY',
            dateColumns: ['updated_at'],
            resizableColumns: true,
            filterable: [
                "actuacion",
                "aavv",
                "mesa",
                "grupo",
                "Situacion",

            ],
            pagination: {
                show: true,
            },
            perPage: 50,
            sortable: [
                "aavv",
                "actuacion",
                "Situacion",
                "mesa",
                "grupo",
            ],
            customSorting: {
                Situacion(ascending) {
                    return (a, b) => {
                        if (ascending) {
                            return a.idactuacioncumplimientoaavv >= b.idactuacioncumplimientoaavv ? 1 : -1;
                        } else {
                            return a.idactuacioncumplimientoaavv <= b.idactuacioncumplimientoaavv ? 1 : -1;
                        }
                    }
                },
                cumplimiento(ascending) {
                    return (a, b) => {

                        if (ascending) {
                            return a.cumplimiento >= b.cumplimiento ? 1 : -1;
                        } else {
                            return a.cumplimiento <= b.cumplimiento ? 1 : -1;
                        }
                    }
                },


                /* Ordena por nombre  sin tener en cuenta los acentos */
                nombre(ascending) {
                    return (a, b) => {
                        if (ascending) {
                            return a.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") >= b.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        } else {
                            return a.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") <= b.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") ? 1 : -1;
                        }
                    }
                },
            },

            sortIcon: {
                is: 'glyphicon-sort',
                base: 'glyphicon',
                up: 'order-up',
                down: 'order-down'
            },
            rowClassCallback(row) {
                let lastParam = window.location.pathname.split("/").pop();
                return (row.idPractica == lastParam) ? 'selected' : '';
            }
        },
        filter: {
            status: '',
            mesa: '',
            grupo: '',
            acuerdo: '',

        }
    }),
    methods: {
        showpopup(event) {
            event.target.classList.toggle('open');
            event.target.nextSibling.classList.toggle('open');
        },
        checkroutepublico() {
            if (this.$route.path.includes('publico')) {
                return true;
            } else {
                return false;
            }
        }
    },
    computed: {
        ...mapGetters({
            cumplimiento: 'getIdCumplimiento',
            objetivo: 'getObjetivo',
            dominios: 'getDominios',
            mesaaavv: 'getAcuerdosVillaMesa',
            grupoaavv: 'getAcuerdosVillaGrupoByMesa',
        }),
        datafiltered() {
            let data = JSON.parse(JSON.stringify(this.data));

            //console.log("data1", data);

            //ESTADO
            if (this.filter.status.length != 0) {
                let status = this.filter.status.map(e => e.id);
                data = data.filter(actuacion => {
                    return (status.some(estatus => (estatus == actuacion.idactuacioncumplimientoaavv) || (estatus == 3 && actuacion.idactuacioncumplimientoaavv >= 3)));
                })
            }

            // MESAS
            if (this.filter.mesa.length != 0) {
                let mesas = this.filter.mesa.map(e => e.id);
                data = data.filter(actuacion => {
                    return (mesas.some(mesa => mesa == actuacion.idmesa));
                })
            }

            // GRUPOS DE TRABAJO
            if (this.filter.grupo.length != 0) {
                let grupos = this.filter.grupo.map(e => e.id);
                data = data.filter(actuacion => {
                    return (grupos.some(grupo => grupo == actuacion.idgrupo));
                })
            }



            /** Devuelvo ordenado por nombre sin tener en cuenta los acentos */
            // data = data.sort((a, b) => {
            //     return a.nombre.localeCompare(b.nombre, 'es', { sensitivity: 'base' });
            // });

            /** Ordeno primero por mesa, luego grupo, luego aavv y por ultimo por nombre */
            data = data.sort((a, b) => {
                if (a.mesa > b.mesa) {
                    return 1;
                } else if (a.mesa < b.mesa) {
                    return -1;
                } else {
                    if (a.grupo > b.grupo) {
                        return 1;
                    } else if (a.grupo < b.grupo) {
                        return -1;
                    } else {
                        if (a.aavv > b.aavv) {
                            return 1;
                        } else if (a.aavv < b.aavv) {
                            return -1;
                        } else {
                            return a.nombre.localeCompare(b.nombre, 'es', { sensitivity: 'base' });
                        }
                    }
                }
            });




            return data;
        },

    },
    filters: {
        compromiso(value) {
            let compromisos = value.split("#");
            compromisos = compromisos.map(c => c.split(":"));
            return compromisos;
        },
        estatus(value) {
            let estatus = {
                'Iniciada': "En ejecución",
                "Restrasada en inicio": "No iniciado",
                "Planificada a futuro": "No iniciado",
                "Pendiente de planificacion": "No iniciado",
                "Terminada": "Terminado"
            }

            return estatus[value];
        },
        dot(value) {
            return new Intl.NumberFormat('es-VE').format(Number(value));
        }
    }
}
</script>