<template>
    <div class="wrapper">

        <section class="encabezado -section" data="acuerdos-villa">

            <h2 class="title -white">Acuerdos de la Villa</h2>

            <h3 class="subtitle">
                <a target="_blank"
                    href="https://transparencia.madrid.es/FWProjects/transparencia/Covid19/MedidasRegeneracion/Ficheros/20200707_AcuerdosDeLaVilla_v2.pdf">
                    El Pleno del Ayuntamiento de Madrid, en sesión extraordinaria celebrada el 7 de julio de 2020, aprobó
                    por unanimidad los denominados Acuerdos de la Villa, documento firmado por el alcalde de Madrid, José
                    Luis Martínez-Almeida, junto con los portavoces de todos los grupos políticos municipales, que recoge
                    352 medidas propuestas por las mesas sectoriales - Mesa Social, Mesa de Estrategia de la Ciudad, Mesa de
                    Economía, Empleo y Turismo y Mesa de Cultura y Deporte-, para la reactivación de Madrid tras la pandemia
                    de la COVID-19.

                    Los Acuerdos de la Villa son el resultado de un intenso esfuerzo de negociación de todos los grupos
                    municipales con la sociedad civil y tienen como objetivo avanzar en los derechos sociales de las
                    personas más vulnerables, que han sufrido con especial intensidad los efectos de la crisis, e intentar
                    incentivar la actividad económica tras el impacto ocasionado por la pandemia.
                </a>
            </h3>

        </section>

        <nav class="breadcrumb -section">
            <div class="container row-start">
                <router-link to="/">Inicio</router-link>
                <router-link :to="$route.fullPath">Acuerdos de la Villa</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>

        <div class="container row-center contenedor-areas">

            <div class="contenedor-input -full" v-if="dominios.ejes">

                <multiselect v-model="areaseleccionada" :options="dominios.acuerdos_villa_mesa"
                    placeholder="Acuerdos de la Villa / Mesa" label="nombre" :tagPosition="'bottom'" :showLabels="false"
                    :showNoResults="false" track-by="nombre">
                </multiselect>

            </div>
        </div>

        <nav class="selector-areas-accion">
            <div class="container row-center" v-if="dominios.acuerdos_villa_mesa">
                <a :active="areaseleccionada.id == area.id" @click="areaseleccionada = area" class="acuerdo-villa-mesa"
                    :key="key" v-for="(area, key) in dominios.acuerdos_villa_mesa" :title="area.nombre"
                    :style="`order:${area.orden}`">{{ area.nombre }}</a>
            </div>
        </nav>

        <section class="contenedor-tip-objetivo row-center ais -section">
            <div class="container row-center">

                <div class="left">
                    <h2>{{ areaseleccionada.nombre }}</h2>
                    <p v-if="areaseleccionada.descripcion" v-html="areaseleccionada.descripcion"></p>
                    <progress-bar :title="'Acuerdos terminados y en ejecución'" :percent="terminadasyenejecucion"
                        :color="'green'">
                    </progress-bar>
                    <!-- <count-down :title="'Tiempo restante del mandato'" :percent="homedata.tiempo_restante"
                        :color="'yellow'">
                    </count-down> -->
                </div>

                <div class="right">


                    <nav class="categories row-start">
                        <!-- <a @click="type = 'indicadores'" :active="type == 'indicadores'" class="btn -light">Indicadores</a> -->
                        <a @click="type = 'acuerdos'" :active="type == 'acuerdos'" class="btn -light">Acuerdos de la
                            Villa</a>
                        <!-- <a @click="type = 'cartas'" :active="type == 'cartas'" class="btn -light">Cartas de
                            servicios</a> -->
                    </nav>

                    <div class="contenedor-datos-graficas row-center">

                        <!-- Opción acuerdos  -->
                        <template v-if="areaseleccionada">

                            <grafica-percent-aavv v-if="areaseleccionada" :total="areaseleccionada.propuestas"
                                :label="'Acuerdos'" :terminadas="areaseleccionada.terminadopropuesta"
                                :ejecucion="areaseleccionada.encursopropuesta"
                                :planificadas="areaseleccionada.noiniciadopropuesta">
                            </grafica-percent-aavv>


                            <div class="stats row-center">
                                <article class="stat -small -terminadas">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.terminadopropuesta" :separator="'.'"></count-to>
                                    </strong>
                                    <span>Terminados</span>
                                </article>

                                <article class="stat -small -ejecucion">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.encursopropuesta" :separator="'.'"></count-to>
                                    </strong>
                                    <span>En ejecución</span>
                                </article>

                                <article class="stat -small -no-iniciadas">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.noiniciadopropuesta"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>No iniciados</span>
                                </article>

                            </div>

                        </template>



                    </div>

                    <!-- Opción actuaciones  -->
                    <!-- <div class="contenedor-datos-graficas row-center">
                        <template v-if="areaseleccionada && type == 'acuerdos'">

                            <grafica-percent v-if="areaseleccionada" :total="areaseleccionada.actuaciones"
                                :label="'Actuaciones'" :terminadas="areaseleccionada.actuacionesterminadasaavv"
                                :ejecucion="areaseleccionada.actuacionesencursoaavv"
                                :planificadas="areaseleccionada.actuacionesnoiniciadasaavv">
                            </grafica-percent>


                            <div class="stats row-center">
                                <article class="stat -small -terminadas">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.actuacionesterminadasaavv"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>Terminadas</span>
                                </article>

                                <article class="stat -small -ejecucion">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.actuacionesencursoaavv"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>En ejecución</span>
                                </article>

                                <article class="stat -small -no-iniciadas">
                                    <strong>
                                        <count-to :endVal="areaseleccionada.actuacionesnoiniciadasaavv"
                                            :separator="'.'"></count-to>
                                    </strong>
                                    <span>No iniciadas</span>
                                </article>

                            </div>

                        </template>
                    </div> -->

                </div>

            </div>

        </section>

        <section class="tabla-areas -section" v-if="areaseleccionada" id="tablaacuerdos">

            <div class="container">
                <header class="encabezado-tabla-areas row-start">


                    <!-- <h3 class="contador -primary" @click="type = 'indicadores'" :active="type == 'indicadores'">
                        <span class="numero">{{ indicadores(areaseleccionada.id).length }}</span>
                        <span class="texto">Indicadores estratégicos, de acción y sectoriales</span>
                    </h3> -->

                    <h3 class="contador -primary" @click="type = 'acuerdos'" :active="type == 'acuerdos'">
                        <span class="numero">{{ areaseleccionada.propuestas }}</span>
                        <span class="texto">Acuerdos</span>
                    </h3>

                    <!-- <h3 class="contador -primary" @click="type = 'cartas'" :active="type == 'cartas'">
                        <span class="numero">{{ cartas(areaseleccionada.id).length }}</span>
                        <span class="texto">Cartas de servicios</span>
                    </h3> -->
                </header>
                <resultados :type="'actuaciones'" :cansearch="true" :data="actuaciones(areaseleccionada.id)"
                    :areaseleccionada="areaseleccionada" v-if="type == 'acuerdos'"></resultados>
                <!-- <resultados :type="'indicadores'" :cansearch="true" :data="indicadores(areaseleccionada.id)"
                    v-if="type == 'indicadores'"></resultados> -->

                <!-- <div class="cartas" v-if="type == 'cartas' && areaseleccionada">
                    <carta v-for="(data, key) in cartas(areaseleccionada.id)" :data="data" :key="key"></carta>
                </div> -->
            </div>

        </section>

        <!-- <banner-ayudamos :data="['acuerdos-villa', 'competencias-areas', 'pleno']"></banner-ayudamos> -->
        <banner-ayudamos :data="['areas-accion', 'plan-estrategico', 'que-es-pog']"></banner-ayudamos>

    </div>
</template>


<script>

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

import resultados from '@/components/parts/resultados-busqueda-aavv'
// import carta from '@/components/parts/carta-de-servicio';

import router from '@/router';

export default {
    name: 'acuerdos-villa',
    mounted() {
        //this.setRandomArea();
        if (this.$route.params.id) {
            this.type = this.$route.params.id;
        }

        let index = 0;
        /** Si viene de la pagina anterior y tiene el parámetro mesaidaavv, entonces carga el area seleccionada */
        if (this.$route.params.mesaidaavv) {
            /** Busco el indice en this.dominios.acuerdos_villa.id = mesaidaavv */
            index = this.dominios.acuerdos_villa_mesa.findIndex(x => x.id == this.$route.params.mesaidaavv);
            this.areaseleccionada = this.dominios.acuerdos_villa_mesa[index];
        } else {
            this.setRandomArea();
        }

        let typeaux = this.type;

        //console.log("typeaux", typeaux)

        this.requestHomeData();
        this.requestDominios();

        setTimeout(() => {

            this.type = typeaux;
            this.areaseleccionada = this.dominios.acuerdos_villa_mesa.find(x => x.id == 0) ? this.dominios.acuerdos_villa_mesa.find(x => x.id == 0) : this.dominios.acuerdos_villa_mesa[0];

        }, 500);


    },
    data: () => ({
        areaseleccionada: '',
        type: 'acuerdos',
    }),
    computed: {
        ...mapGetters({
            homedata: 'getHomedata',
            dominios: 'getDominios',
            actuaciones: 'getActuacionesByMesaAAVV',

            acuerdos: 'getAcuerdosVillaMesa'
        }),
        noiniciadas() {
            return Number(this.areaseleccionada.actuacionesnoiniciadasaavv);
        },
        terminadasyenejecucion() {

            if (!this.areaseleccionada) {
                return 0;
            }

            let terminadas = Number(this.areaseleccionada.terminadopropuesta) + Number(this.areaseleccionada.encursopropuesta);
            let porcentaje = (terminadas * 100) / Number(this.areaseleccionada.propuestas);

            return Number(porcentaje);
        }
    },
    methods: {
        ...mapActions(['requestHomeData', 'requestDominios']),
        setRandomArea() {
            if (this.dominios.acuerdos_villa_mesa) {
                //this.areaseleccionada = this.dominios.acuerdos_villa_mesa[0];
                this.areaseleccionada = this.dominios.acuerdos_villa_mesa.find(x => x.id == 0) ? this.dominios.acuerdos_villa_mesa.find(x => x.id == 0) : this.dominios.acuerdos_villa_mesa[0];
            }
        },
        back() {
            router.go(-1);
        }
    },
    components: {
        'resultados': resultados,
    },
    watch: {
        'dominios.acuerdos_villa_mesa'() {
            this.setRandomArea();
        },
        'type'() {
            let tablaareas = document.getElementById('tablaacuerdos');
            scroll({
                top: tablaareas ? tablaareas.offsetTop - 200 : 0,
                behavior: "smooth"
            });

            router.replace({
                params: {
                    id: this.type
                }
            }).catch(() => { });
        },
        'areaseleccionada'() {
            this.show = false;

            /** agrego a los parametros de router mesaidaavv */
            router.currentRoute.params.mesaidaavv = this.areaseleccionada.id;

            //console.log("areaseleccionada", this.areaseleccionada);

        }
    }
}
</script>

<style lang="scss" scoped>
.contenedor-areas {
    .contenedor-input {
        max-width: 400px;
    }
}
</style>